 ::-webkit-scrollbar {
   width: 4px !important;
   height: 6px !important;
 }

 /* this targets the default scrollbar (compulsory) */

 /* Track */
 ::-webkit-scrollbar-track {
   background: transparent !important;
 }

 /* Hide Number Input Roller */
 input[type=number]::-webkit-inner-spin-button,
 input[type=number]::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: rgba(0, 0, 0, 0.23) !important;
   border-radius: 24px;
   transition: all 0.5s;
   cursor: pointer;
 }

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: rgba(146, 151, 165, 1) !important;
 }

 /* this will style the thumb, ignoring the track */

 ::-webkit-scrollbar-button {
   background-color: transparent !important;
 }

 /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

 ::-webkit-scrollbar-corner {
   background-color: transparent !important;
 }


 iframe::-webkit-scrollbar-thumb {
   background: rgba(0, 0, 0, 0.23) !important;
   border-radius: 24px;
   transition: all 0.5s;
   cursor: pointer;
 }

 /* Handle on hover */
 #viewer #container #main #scroller::-webkit-scrollbar-thumb:hover {
   background: rgba(146, 151, 165, 1) !important;
 }

 /* this will style the thumb, ignoring the track */

 iframe::-webkit-scrollbar-button {
   background-color: transparent !important;
 }

 /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

 iframe::-webkit-scrollbar-corner {
   background-color: transparent !important;
 }

 /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
 @font-face {
  font-family: "NotoSans-SemiBold";
  src: url("./font/NotoSans-SemiBold.ttf");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url("./font/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "NotoSans-ExtraBold";
  src: url("./font/NotoSans-ExtraBold.ttf");
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url("./font/NotoSans-Bold.ttf");
}

@font-face {
  font-family: "NotoSans-Italic";
  src: url("./font/NotoSans-Italic.ttf");
}

/* ARABIC FONT FAMILY */


 /* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
 html {
   height: 100%;
 }

 #root {
   height: 100%;
 }

 /* input-range-slider */
 /* input-range-track */
 /* _input-range-slider.scss */
 .input-range__slider {
   border-radius: 100%;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 30px;
   height: 30px;
   margin-left: -1rem;
   margin-top: -18px;
   outline: none;
   position: absolute;
   top: -1px;
   transition: left 200ms ease-out;
   will-change: left;
 }

 .input-range__slider::before {
   background: #fff;
   border-radius: 100%;
   display: block;
   content: '';
   height: 20px;
   width: 20px;
   transition: transform 200ms ease-out, box-shadow 200ms ease-out;
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
   will-change: transform, box-shadow;
   border: 5px solid #5078E1;
 }

 .input-range__slider:active::before {
   transform: scale(1.15);
 }

 .input-range--disabled .input-range__slider::before {
   background: #ccc;
   border: 1px solid #ccc;
   box-shadow: none;
   transform: none;
 }

 /* _input-range-label.scss */
 /* not imported */
 /* _input-range-label-container.scss */
 .input-range__label-container {
   display: none;
 }

 .input-range__label-container .input-range__label--min,
 .input-range__label-container .input-range__label--max {
   display: none;
 }

 /* _input-range-track.scss */
 .input-range__track {
   background: #e6e9ea;
   border-radius: 3px;
   cursor: pointer;
   display: block;
   height: 10px;
   position: relative;
   transition: left 200ms ease-out, width 200ms ease-out;
   will-change: left, width;
 }

 .input-range--disabled .input-range__track {
   background: #e6e9ea;
 }

 .input-range__track--background {
   margin-top: -1.5px;
   top: 50%;
   position: absolute;
   left: 0;
   right: 0;
 }

 .input-range__track--active {
   background: #5078E1;
 }

 /* input-range */
 .input-range {
   height: 1rem;
   position: relative;
   width: 100%;
 }

 .options {
   padding: 2px;
 }

 .MuiTimelineItem-root:before {
   flex: 0 !important;
 }

 /* .recharts-sector {
  stroke-linejoin: round;
  stroke-width: 7px;
} */

 .react-datepicker__month-read-view--down-arrow {
   right: 0px;
   margin-top: 4px;
 }

 .datepicker__month-dropdown-container--scroll {
   display: flex;
   align-items: center;
 }

 .react-datepicker__month-read-view {
   display: flex;
   align-items: center;
 }

 .react-datepicker__month-option {
   background-color: #fff;
   padding: 4px;
 }

 .css-1eppkgy-MuiTypography-root-MuiTimelineContent-root{
  padding: 6px 6px 24px 12px;
 }

 .react-tel-input .country-list .search{
  display: flex !important;
 }

 .react-tel-input .country-list{
  box-shadow: none !important;
  border: 1px solid #E4E8EE;
  border-radius: 4px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-SemiBold' !important;
  font-weight: 500;
 }

 .react-tel-input .form-control{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-SemiBold' !important;
  font-weight: 500;
 }

 .react-tel-input .country-list .search-box{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-SemiBold' !important;
  font-weight: 500;
 }

 .react-tel-input .form-control{
  width: 100% !important;
  height: 45px;
 }

 .react-tel-input .flag-dropdown{
  background-color: none;
  border: none;
 }

 .react-tel-input .selected-flag{
  width: 40px !important;
 }

 .dropdown-top {
  position: absolute;
  top: -100%; /* Adjust this value to position the dropdown correctly */
  transform: translateY(-100%); /* Adjust this value to position the dropdown correctly */
}

.ql-toolbar.ql-snow .ql-formats{
  margin-right: 8px !important;
}
.image {
  background-color: red !important
}

.MuiTab-root {
  padding: 0px !important
}

.react-datepicker-wrapper{
  width: 100%;
}
html[dir="rtl"] {
  font-size: 110% !important;
}
html[dir="ltr"] {
  font-size: 100% !important;
}
.MuiTypography-root {
  letter-spacing: 0;
}